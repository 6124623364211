import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../../components/header_resources"

export default function OneUpWallStreet() {
  const heroTitle = "One Up on Wall Street – Peter Lynch"

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="w-100 mb-5 sm:mmb-5">
        <Link class="back--home" to="/resources/book-summaries">
          <i class="fas fa-chevron-left"></i> <span>Back to Book Summaries</span>
        </Link>
      </div>
      <div class="innerpage--content innerpage--content-sm blog--details m-0 p-0">
        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <p>
          I read the millennium edition of this book. I have set out below a summary, key takeaways
          / learning outcomes, some ramblings and unanswered questions.
        </p>

        <h2 class="h3 h3-sm">“Own only what you understand”.</h2>
        <p>
          In evaluating and picking stocks both “
          <strong>future and ancient fundamentals are relevant</strong>”. It is also not always a
          good idea to pick stocks that are in the fancy. In respect of ancient fundamentals, I was
          wondering – what about turnaround companies? Their ancient fundamentals / past balance
          sheets may look damaged but if the industry and the company itself is turning around –
          does one ignore the opportunity? One also needs to consider whether the existing market
          cap justifies past and future earnings.
        </p>
        <p>A few clunkers are OK so long as the winners more than make up for it. </p>
        <p>
          Big companies are not bearproof. Also, just because a company has become big does not by
          itself provide a justification for it not to become a multibagger and continue growth –
          case in point being TCS or HDFC Bank!{" "}
        </p>
        <p>
          “
          <strong>
            Do not rely on brokerage recommendations, hot tips and what others are buying
          </strong>
          ” but give credence when the “promoters or the insiders are buying”. Well of course buying
          solely based on tips or recommendations of brokerages or what some hot shot investor has
          bought is foolhardy.{" "}
        </p>
        <p>
          “
          <strong>
            Spectacular returns can be generated even from big companies and buying should not be
            limited only to small and midcaps
          </strong>
          ”. Of course, a big company bought at historically low levels can provide spectacular
          returns provided the price falls are transient or a result of some “curable” problems. The
          probability, however, of small and micro and mid caps providing mega returns is higher so
          is of theirs to permanently destroy capital. But big companies rarely provide the big
          moves. A small company even in a slow growing industry can be a decent compounder of
          wealth. In this regard, I recommend reading a Zebra in Lion Country by Ralph Wanger.
        </p>
        <p>
          “<strong>Investing is an art and not a science</strong>”. I agree – more than the number
          crunching or the hard skills, it’s the softer skills that come into play. Being observant
          (looking around things in daily personal and professional life can generate investment
          ideas), logic, being lazy, minimizing your decisions, controlling or overcoming your
          biases, having efficient mental models, letting go off opportunities or momentum play and
          watching others make money and keeping it simple are quite relevant. Having a long-term
          orientation is in itself a big advantage and that is not necessarily a science or
          something quantifiable.{" "}
        </p>
        <p>
          “<strong>Street Lag</strong>” – This is one of my favourite categories amongst stocks. I
          agree when Lynch recommends that it is better to buy stocks with less institutional
          ownership and minimal analyst coverage. Good “off the radar” companies which are yet to be
          discovered by Dalal Street could be the best buys. The level of diligence and conviction
          required is higher as there is no / minimal institutional ownership to “validate” your
          thesis or conviction.{" "}
        </p>
        <p>
          “<strong>Right Stock Right Price Right Time</strong>” – Difficult to find but that’s when
          patience and discipline are tested. Buying right stocks at the wrong price at the wrong
          time can lead to a disaster. A good company may not be a good stock if the price is not
          right. The people who bought Wipro – a brilliant company – at the peak of the 2000 boom,
          took several years to generate decent returns on their investment.{" "}
        </p>
        <p>
          Assuming that we pass the test of “Do I Own a House?” and “Do I Need the Money?” – I agree
          that the qualities mentioned by Lynch are quite important. I will list them: patience,
          self-reliance, common sense, a tolerance for pain, open-mindedness, detachment,
          persistence, humility, flexibility, a willingness to do independent research, an equal
          willingness to admit to mistakes, and the ability to ignore general panic. Any quality
          that might be missing can be cultivated with practice and perseverance – I think. These
          qualities in themselves are quite an edge to have.{" "}
        </p>

        <h2 class="h3 h3-sm">
          “Avoiding the hottest stock in the hottest industry and the stocks that diworseify”{" "}
        </h2>
        <p>
          “
          <strong>
            It is OK to jump on to the bandwagon a little later when the company and the business
            have proven their worth
          </strong>
          ”- my two cents on this is while a ten bagger will also be a two bagger after it has
          already become a 5 bagger, the price of “certainty” is also high. “Late jumping” could
          also introduce the bias of when I didn’t buy at 100, how do I buy at 400 or I bought at
          100 how do I average up? But then again return of capital is more important than the
          return on capital.
        </p>
        <p>
          “<strong>Earnings, Earnings and Earnings</strong>” – like for restaurants its location,
          location and location, stocks have a similar rule. But then again there could be minor
          blips in earnings or when a company is going through a capex cycle – so understanding the
          rationale behind an upturn or downturn in earnings also becomes important.
        </p>
        <p>
          “<strong>Preparing the monologue re the story of the stock</strong>” – quite important –
          rather helpful to have a decision making journal and write it up as well. Updating the
          monologue and reconfirming and rechecking the investment thesis on a regular basis is
          equally important.
        </p>
        <p>
          “<strong>Keep your eyes and ears open!</strong>” – it is basically do not just see but
          observe. You will see most people smoking ITC’s cigarettes or most complex infrastructure
          projects being done by L&T or most cars being sold by Maruti Suzuki – just observing these
          things provides a lot of stock ideas.
        </p>
        <p>
          “<strong>Holding Companies</strong>” – they usually trade at a discount to the
          underlyings. When the discount is huge – returns could be massive once the discount
          narrows. Maharashtra Scooters is an example that comes to mind.
        </p>
        <p>
          “<strong>Be suspicious of companies with growth rates of 50-100% per year</strong>” – so
          basically be ultra cautious about ultra fast growers. But what if the ultra fast grower is
          a creator or first mover into an ultra fast growing industry or market? I guess the idea
          is to proceed with caution rather than over confidence and extra optimism in such cases.
        </p>
        <p>
          “
          <strong>
            Don’t have high return expectations and that will keep the emotions and frustrations
            during the downturns in check
          </strong>
          ” - this leads to bad decisions. It is not always necessary to outperform the market or
          other investors. If you are earning more than other assets over time through equities with
          the level of risk you are prepared to take, that should be OK.
        </p>
        <p>
          “<strong>fixed number for the stocks you own. Knowledgeable buying is the key</strong>” –
          Of course, too many stocks are an avoidable distraction.
        </p>
        <p>
          Ignore noise, news – develop second level thinking and superior insights – this is a task
          in itself given the information deluge happening all the time. One needs to transcend all
          the mental and emotional impact of all that while making investment decisions.
        </p>
        <p>
          “
          <strong>
            When you invest in stocks, you have to have a basic faith in human nature, in
            capitalism, in the country at large and future prosperity in general
          </strong>
          ” – could not agree more.
        </p>
        <p>
          “<strong>A stock does not know that you own it</strong>” – again getting emotionally
          attached to a stock or a company i.e. developing “attachment bias” clouds prudent
          judgment. It is important to divorce the emotions from investment decisions – difficult to
          implement in practice and, therefore, rewarding if implemented.
        </p>
      </div>
    </HeaderResources>
  )
}
